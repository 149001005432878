import React, { Component } from 'react'
import './index.less'
import { Input } from 'antd'
import img from '../../style/imgs/lz2.jpg';
export default class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tabKey: 0,
            keyWord: ''
        }
    }
    handleTo = (id, e) => {
        console.log(id)
        e.preventDefault()
        if (id) {
            let element = document.getElementById(id);
            if (element) {
                element.scrollIntoView();
            }
        }
    }
	openSCJGJ () {
		window.open('http://wap.scjgj.sh.gov.cn/businessCheck/verifKey.do?showType=extShow&serial=9031000020181022104223000002902468-SAIC_SHOW_310000-4028e4cb6c9a5291016e0a165b605b37968&signData=MEUCIAg+hdz3fvvjGWqpa+E1G1DcmwuTPn/UakApqFZ2INI3AiEAyOxhvOuV16JstmfZdxB2CVPCJ6ObZSj3ZYMyJ1YgsFM=')
	}
    down = () => {
        window.open('https://exe-1259007386.cos.ap-shanghai.myqcloud.com/%E6%99%BA%E9%85%8D%E5%AE%9DAi%20%E6%99%BA%E8%83%BD%E6%B1%BD%E9%85%8D%E5%95%86%E7%B3%BB%E7%BB%9F%20v1.45.exe')
    }
    changeTab = (key) => {
        this.setState({
            tabKey: key
        })
    }
    changeKeyWord = (e) => {
        this.setState({
            keyWord: e.target.value
        })
    }
    searchByKeyword = () => {
        console.log(this.state.keyWord)
    }
    render() {
        const placeholder = this.state.tabKey === 0 ? '请输入VIN' : '请输入oe零件号'
        return (
            <div className='static'>
                <header>
                    <div className="header-wrap">
                        <a href="" className="logo" href='/index.html'>
                            {/*<img src={require('@/style/imgs/index-logo.png')} alt="" />*/}
                            <img style={{height: '62px',width: '62px' }} src={require('@/style/imgs/logo.png')} alt="" /><span>浙商+保配车联</span>
                        </a>
                        <ul className="header-nav">
                            <li>
                                <a href='#/' onClick={this.handleTo.bind(this, 'banner')}>首页</a>
                            </li>
                            <li>
                                <a href='#/' onClick={this.handleTo.bind(this, 'title')}>产品</a>
                            </li>
                            <li>
                                <a href='#/' onClick={this.handleTo.bind(this, 'security')}>合作伙伴</a>
                            </li>
                            <li>
                                <a href='#/' onClick={this.handleTo.bind(this, 'ft')}>联系我们</a>
                            </li>
                        </ul>
                        <div className="header-right">
                            <a href="#/login" className="btn-top">登录/注册</a>
                        </div>
                    </div>
                </header>
                <div className="banner" id='banner'>
                    <div className="banner-box">
                        <div className="banner-part fadeInLeft animated wow">
                            <div className="search">
                                <div className="tabs">
                                    <div onClick={this.changeTab.bind(this, 0)} className={this.state.tabKey === 0 ? 'checked' : null}>
                                        <p>VIN</p>
                                    </div>
                                    <div onClick={this.changeTab.bind(this, 1)} className={this.state.tabKey === 1 ? 'checked' : null}>
                                        <p>OE</p>
                                    </div>
                                </div>
                                <Input.Search
                                    placeholder={placeholder}
                                    value={this.state.keyWord}
                                    onChange={this.changeKeyWord}
                                    style={{ width: 500 }}
                                    enterButton
                                    onSearch={this.searchByKeyword}
                                    size='large'
                                />
                            </div>
                            <div className="show">
                                <div className='wrap-show'>
                                    <div className="item-show">
                                        <p>汽车品牌</p>
                                        <p>27个</p>
                                    </div>
                                    <div className="item-show">
                                        <p>车型库</p>
                                        <p>31000款</p>
                                    </div>
                                    <div className="item-show">
                                        <p>oe零件号</p>
                                        <p>8000万个</p>
                                    </div>
                                    <div className="item-show">
                                        <p>Sku商品</p>
                                        <p>31万种</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="banner-right fadeInRight wow animated">
                            <h1>欢迎使用 浙商+保配车联 汽配网络询价系统</h1>
                            <h2>开启汽车配件智能化工作之旅</h2>
                            <div className="btn-banner">
                                <a href='#/login' className="btn-to" onClick={this.down}>立即下载</a>
                                <a href='#/login' className="btn-to">进入网页版</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div id='title'>
                    {/* <h1>功能实用的效率系统</h1>
                    <p>都有哪些功能?</p> */}
                </div>
                <section className='detail'>
                    <div className="wrap-a">
                        <div className="detail-wrap">
                            <div className="detail-img-doc">
                                <img src="https://shared-https.ydstatic.com/market/ynote-website/ic-cone.svg" alt="cone"
                                    data-wow-delay="0.3s" className="ic-01-cone wow fadeInLeft animated" />
                                <img src={require('../../style/imgs/WechatIMG48.jpeg')} alt="computer"
                                    className="ic-01-part wow fadeIn animated" />
                                {/* <div className="ic-01-hide">
                                    <img src="https://shared-https.ydstatic.com/market/ynote-website/ic-01-red.svg"
                                        data-wow-offset="200" className="ic-01-red wow bounceInDown animated" />
                                    <img src="https://shared-https.ydstatic.com/market/ynote-website/ic-01-yellow.svg"
                                        data-wow-delay="0.1s" className="ic-01-yellow wow bounceInDown animated" />
                                    <img src="https://shared-https.ydstatic.com/market/ynote-website/ic-01-green.svg"
                                        data-wow-offset="200" data-wow-delay="0.2s" className="ic-01-green wow bounceInDown animated" />
                                    <img src="https://shared-https.ydstatic.com/market/ynote-website/ic-01-blue-1.svg"
                                        data-wow-delay="0.3s" className="ic-01-blue wow bounceInDown animated" />
                                </div>  */}
                            </div>
                            <div className="detail-des detail-doc wow fadeInRight">
                                <h2>
                                    <span>浙商+保配车联 Ai智能汽配报价引擎</span>
                                </h2>
                                <p>以人工智能实现汽配销售的颠覆式提升，提升报价一致性，提升10倍报价速度，减少87%报价劳动强度，减少90%报价员培养时间。</p>
                                <br/>
                                <p>与云险、精友、天安等十余家电商，保险互联互通，无需手工报价，后台自动下单。</p>
                            </div>
                        </div>
                    </div>
                    <div className="wrap-b">
                        <img src="https://shared-https.ydstatic.com/market/ynote-website/ic_02_cube2.svg" alt=""
                            className="ic-02-cubes wow fadeInDown animated" />
                        <div className="detail-wrap">
                            <div className="detail-img-collect">
                                <img src="https://shared-https.ydstatic.com/market/ynote-website/ic_02_cube2.svg" alt=""
                                    data-wow-delay="0.2s" className="ic-02-cube wow fadeInLeftBig animated" />
                                <div className="ic-02-phone-hide">
                                    <img src={require('../../style/imgs/WechatIMG50.png')}
                                        data-wow-delay="0.3s" className="ic-02-phone wow fadeInUp animated" />
                                </div>
                                {/* <img src="https://shared-https.ydstatic.com/market/ynote-website/ic-02-logo.svg" alt=""
                                    data-wow-delay="0.6s" className="ic-02-logo wow zoomInUp animated" /> */}
                            </div>
                            <div className="detail-des detail-collect wow fadeInLeft">
                                <h2>
                                    <span>浙商+保配车联</span>，新一代智能版汽配电商系统
                                </h2>
                                <p>
                                    基于图像识别、自然语义处理、语音识别等Ai技术，实现秒级配件批量输入，秒级价格回复，实时价格更新，ERP互联互通等。
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="wrap-a">
                        <div className="detail-wrap wrap-ocr">
                            <div className="detail-img-ocr">
                                <img src={require('../../style/imgs/epc.jpeg')} alt=""
                                    data-wow-delay="1.6s" className="ic-03-wave wow zoomIn animated" />
                                {/* <img src="https://shared-https.ydstatic.com/market/ynote-website/ic-03-page-2@3x.png" alt=""
                                    data-wow-offset="200" className="ic-03-page-2 wow fadeInLeftBig animated" />
                                <img src="https://shared-https.ydstatic.com/market/ynote-website/ic-03-page-1.png" alt=""
                                    data-wow-delay="0.1s" className="ic-03-page-1 wow fadeInLeftBig animated" />
                                <div data-wow-delay="0.1s" data-wow-offset="200" className="scanbar-f wow fadeInLeftBig animated">
                                    <img className="ic-03-scanbar wow"
                                        src="https://shared-https.ydstatic.com/market/ynote-website/ic-03-scanbar.svg" alt="" />
                                </div>
                                <img src="https://shared-https.ydstatic.com/market/ynote-website/ic-03-blue.svg" data-wow-offset="0"
                                    alt="" data-wow-delay="0.8s" className="ic-03-blue wow zoomIn animated" /> */}
                            </div>
                            <div className="detail-des detail-ocr wow fadeInRight">
                                <h2>
                                    <span>免费VIN查询</span>，实车EPC
                                </h2>
                                <p>27大汽车品牌，免费VIN解析，千万级OE码数据，建立EPC数据与实物车型、实物配件、真实库存三者打通的超级EPC。</p>
                            </div>
                        </div>
                    </div>
                    <div className="wrap-b">
                        <img src="https://shared-https.ydstatic.com/market/ynote-website/ic_02_cube2.svg" alt="cube"
                            className="ic-04-cube wow fadeInDown animated" />
                        <img src="https://shared-https.ydstatic.com/market/ynote-website/ic-cone.svg" alt="cone"
                            className="ic-04-cone wow fadeInUp animated" />
                        <div className="detail-wrap">
                            <div className="detail-img-sides">
                                <img src={require('../../style/imgs/stock.png')} alt=""
                                    className="ic-04-computer wow fadeIn animated" />
                                {/* <img
                                    src="https://shared-https.ydstatic.com/market/ynote-website/ic-04-iphone@2x.png" alt=""
                                    data-wow-delay="0.3s" className="ic-04-iphone wow fadeInLeft animated" />
                                <img src="https://shared-https.ydstatic.com/market/ynote-website/ic-04-android@2x.png" alt=""
                                    className="ic-04-android wow fadeInLeft animated" />
                                <img src="https://shared-https.ydstatic.com/market/ynote-website/ic-04-ipad@2x.png" alt=""
                                    className="ic-04-ipad wow fadeInRight animated" />
                                <img src="https://shared-https.ydstatic.com/market/ynote-website/ic-04-sync-shadow.svg"
                                    data-wow-offset="10" alt="" data-wow-delay="0.3s" className="ic-04-sync-shadow wow fadeIn animated" />
                                <img src="https://shared-https.ydstatic.com/market/ynote-website/ic-04-sync.svg"
                                    data-wow-offset="10" alt="" data-wow-delay="0.3s" className="ic-04-sync wow rotateIn animated" /> */}
                            </div>
                            <div className="detail-des detail-sides wow fadeInLeft">
                                <h2>
                                    <span>库存共享</span>，构建汽配共享新生态
                                </h2>
                                <p>将共享经济融入汽配行业之中，同行联盟，一键调货，快捷下单，ERP直连，降低库存，提高库存周转率。</p>
                            </div>
                        </div>
                    </div>
                    <div className="wrap-a">
                        <div className="detail-wrap">
                            <div className="detail-img-doc">
                                <img src="https://shared-https.ydstatic.com/market/ynote-website/ic-cone.svg" alt="cone"
                                    data-wow-delay="0.3s" className="ic-01-cone wow fadeInLeft animated" />
                                <img src={require('../../style/imgs/national.png')} alt="computer"
                                    className="ic-01-part wow fadeIn animated" />
                                {/* <div className="ic-01-hide">
                                    <img src="https://shared-https.ydstatic.com/market/ynote-website/ic-01-red.svg"
                                        data-wow-offset="200" className="ic-01-red wow bounceInDown animated" />
                                    <img src="https://shared-https.ydstatic.com/market/ynote-website/ic-01-yellow.svg"
                                        data-wow-delay="0.1s" className="ic-01-yellow wow bounceInDown animated" />
                                    <img src="https://shared-https.ydstatic.com/market/ynote-website/ic-01-green.svg"
                                        data-wow-offset="200" data-wow-delay="0.2s" className="ic-01-green wow bounceInDown animated" />
                                    <img src="https://shared-https.ydstatic.com/market/ynote-website/ic-01-blue-1.svg"
                                        data-wow-delay="0.3s" className="ic-01-blue wow bounceInDown animated" />
                                </div> */}
                            </div>
                            <div className="detail-des detail-doc wow fadeInRight">
                                <h2>
                                    <span>97万家询价人信息</span>，免费查
                                </h2>
                                <p>查看询价人的名称，地址，电话，位置，照片等基础信息，维护客情关系，挂账之前，先查询做功课，减少风险。</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="technology" id='technology'>
                    <div className="security-link">
                        <a href="./certification/certification-csa.html">
                            <div data-wow-offset="10" className="ic-05-wechat wow fadeInUp animated">
                                <img src={require('@/style/imgs/index-technology01.png')} />
                                <span>上海市高新技术企业</span>
                            </div>
                        </a>
                        <a href="./certification/certification-cans.html">
                            <div data-wow-offset="10" data-wow-delay="0.3s" className="ic-05-wechat wow fadeInUp animated" >
                                <img src="https://org.modao.cc/uploads4/images/3803/38035373/v2_pvxex5.png" />
                                <span>复旦管理创业大赛二等奖</span>
                            </div>
                        </a>
                        <a href="./certification/certification-ukas.html">
                            <div data-wow-offset="10" data-wow-delay="0.6s" className="ic-05-wechat wow fadeInUp animated">
                                <img src={require('@/style/imgs/index-technology03.jpeg')} />
                                <div className='wrap-text'>
                                    <p>
                                        <span>山东新旧功能转换基金奖励</span>
                                        <span>山东省高层次人才专家成员单位</span>
                                    </p>
                                </div>
                            </div>
                        </a>
                    </div>
                </section>
                {/*<div className="security" id='security'>*/}
                {/*    <div className='security-title wow bounce animated'>*/}
                {/*        <span>合作客户</span>*/}
                {/*    </div>*/}
                {/*    <div className="security-line wow bounceInLeft animated"></div>*/}
                {/*    <div className="security-link">*/}
                {/*        <a href="http://www.zking.com/" target='__blank'>*/}
                {/*            <img data-wow-offset="10" className="ic-05-wechat wow fadeInUp"*/}
                {/*                src="http://www.zking.com/r/cms/www/default/static/img/logo-w.png" />*/}
                {/*        </a>*/}
                {/*        <a href="https://www.sinosig.com/" target='__blank'>*/}
                {/*            <img data-wow-offset="10" data-wow-delay="0.3s" className="ic-05-wechat wow fadeInUp animated"*/}
                {/*                src="https://www.sinosig.com/common/index_new2014/images/logonew.png" />*/}
                {/*        </a>*/}
                {/*        <a href="http://www.cntaiping.com/" target='__blank'>*/}
                {/*            <img data-wow-offset="10" data-wow-delay="0.6s" className="ic-05-wechat wow fadeInUp animated"*/}
                {/*                src="http://www.cntaiping.com/tplresource/cms/www/taiping/img/home_new/tp_logo_img.png" />*/}
                {/*        </a>*/}
                {/*    </div>*/}
                {/*    <div className="security-link">*/}
                {/*        <a href="http://www.jingyoutimes.com/" target='__blank'>*/}
                {/*            <img data-wow-offset="10" data-wow-delay="0.6s" className="ic-05-wechat wow fadeInUp animated" src="http://www.jingyoutimes.com/images/gw_sy_dh_menu_logo.png" alt="" />*/}
                {/*        </a>*/}
                {/*        <a href="http://www.cic.cn/" target='__blank'>*/}
                {/*            <img data-wow-offset="10" data-wow-delay="0.6s" className="ic-05-wechat wow fadeInUp animated" src="http://www.cic.cn//r/cms/cic/default/images/logo_2019.jpg" alt="" />*/}
                {/*        </a>*/}
                {/*        <a href="http://www.ccic-net.com.cn/" target='__blank'>*/}
                {/*            <img data-wow-offset="10" data-wow-delay="0.6s" className="ic-05-wechat wow fadeInUp animated" src="http://www.95590.cn/images/2018transform/common/logo.jpg" alt="" />*/}
                {/*        </a>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <section id='fast-signin' className='wow flipInX animated'>
                    <h2>立即使用浙商+保配车联，开启汽配智能工作之旅</h2>
                    <a href="#/login" className='btn-login'>立即登录</a>
                </section>
				<div style={{textAlign: 'center',marginTop: '5px'}}>
				<img src={img} alt="电子营业执照" onClick={this.openSCJGJ}/>
				</div>
                <footer className='ft-static' id='ft'>
                    <div className="copyright"> Copyright © 2018-2019.浙商+保配车联 All rights reserved &nbsp;&nbsp;&nbsp;&nbsp; 沪ICP备19007361号
                    </div>
                </footer>
            </div>
        )
    }
}
