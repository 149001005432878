import React from 'react';
import { Menu, Icon } from 'antd';
import { Link } from 'react-router-dom';
let handleClick = (item, index) => {
    let list = []
    if (item.subs) {
        list = item.subs
    } else {
        list.push(item)
    }
    sessionStorage.setItem('activeRoute', index)
    sessionStorage.setItem('route', JSON.stringify(list))
}

const renderMenuItem = (item) => ( // item.route 菜单单独跳转的路由
    <Menu.Item
        key={item.key}
        onClick={handleClick(item)}
    >
        <Link to={(item.route || item.key) + (item.query || '')}>
            {item.icon && <Icon type={item.icon} />}
            <span className="nav-text">{item.title}</span>
        </Link>
    </Menu.Item>
);

const renderSubMenu = item => (
    <Menu.SubMenu
        key={item.key}
        title={
            <span>
                {item.icon && <Icon type={item.icon} />}
                <span className="nav-text">{item.title}</span>
            </span>
        }
    >
        {item.subs.map(item => renderMenuItem(item))}
    </Menu.SubMenu>
);

// export default class SideMenu({ menus, ...props }) => {
export default class SideMenu extends React.Component {

    // <Menu {...props}>
    //     {menus && menus.map(item =>
    //         item.subs ? renderSubMenu(item) : renderMenuItem(item)
    //     )}

    //     {menus && menus.map(item =>
    //         <Menu.Item
    //             key={item.key}
    //             onClick={(e) =>handleClick(item)}
    //         >
    //             <Link to={(item.route || item.key) + (item.query || '')}>
    //                 {item.icon && <Icon type={item.icon} />}
    //                 <span className="nav-text">{item.title}</span>
    //             </Link>
    //         </Menu.Item>
    //     )}
    // </Menu>
    constructor(props) {
        super(props)
    }
    render() {
        let active = -1
        const { menus, location} = this.props
        menus.forEach((menu, index) => {
            if(menu.key === location.pathname) {
                active = index
            } else if(menu.subs){
               const subs = menu.subs
               subs.forEach((sub) => {
                  if(sub.key === location.pathname) {
                      active = index
                  }
               })
            }
        })
        const version = 'v1.71'
        return (
            <ul className='global-menu'>
                {menus && menus.map((item, index) =>
                    <li
                        key={item.key}
                        onClick={(e) => handleClick(item, index)}
                        className={`${active == index ? 'active' : ''}`}
                    >
                        <Link to={(item.route || item.key) + (item.query || '')}>
                            {item.icon && <Icon type={item.icon} />}
                            <span className="nav-text">{item.title}</span>
                        </Link>
                    </li>
                )}
                <li style={{marginTop: 10}}>
                    <span className="nav-text">配件商版</span>
                    <span className="nav-text">{version}</span>
                </li>
            </ul>
        )
    }
};
