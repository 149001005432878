
let url_base = 'http://49.234.130.43:9601'

//保存草稿
export const URL_save_price=url_base+'/save_price/';


export const URL_test = url_base+ '/test';
//登陆
// export const URL_login = url+ '/api/login';2022年1月8日22:07:01废弃
export const URL_login = url_base+ '/login';
export const URL_hu_login = url_base + '/login'
//注册
export const URL_register = url_base+ '/register';

//忘记密码
export const URL_reset_password = url_base+ '/api/reset_password';

//修改密码
export const URL_modify_password =  '/api/modify_password';

//发送短信  /api/get_verification_code/{mobile}
export const URL_get_verification_code = '/api/get_verification_code/';
//注册公司

export const URL_vendor_account_profile_company = '/api/vendor/account/profile/company';

//根据名字查询公司

export const URL_vendor_account_query_company = '/api/vendor/account/query/company';


//图片解析
export const URL_vin_screenshot_analysis =url_base+'/api/vin/screenshot/analysis'
//获取autogroup
export const URL_vin_find_autogroups =url_base+'/api/vin/find/autogroups'
export const URL_vin_for_groupid = url_base+'/vin_for_groupid'
// 语音报价 启动标准化
export const URL_parts_text_to_stdname = url_base + '/text_to_stdname'
// 调货按钮
export const URL_shop_change = url_base + '/shop_change'
//零件识别接口图片解析
export const URL_parts_screenshot_analysis =url_base+'/api/parts/screenshot/analysis'
//昵称获取标准名:
export const URL_std_for_stdname =url_base+'/std_for_stdname'
//订单中心
export const URL_ai_order_list =url_base+'/api/ai/order/list'
//获取车型的主要特征
export const URL_vin_find_auto =url_base+'/api/vin/find/auto'
//截屏报价
export const URL_id_and_std_search =url_base+'/id_and_std_search'
//修改报价
export const URL_modify_price=url_base+'/modify_price'
//共享的查询
export const URL_search_sku =url_base+'/search_sku'
//对外库存共享
export const URL_share_for_other =url_base+'/share_for_other'
//
export const URL_share_for_price =url_base+'/share_for_price'
//向我共享
export const URL_share_for_me =url_base+'/share_for_me'
// 查看共享
export const URL_share_search = url_base+'/new_search'
export const URL_look_share_new = url_base+'/look_share_new'

// //
// export const URL_share_for_me_connect_seller =url_base+'/share_for_meconnect_seller'
//商品列表
export const URL_api_parts_sku_list=url_base+'/api/parts/sku/list'


//商品设置价格修改
export const URL_api_goods_manage = url_base+'/goods_manage'

// 特价设置
export const URL_special_price_new = url_base + '/special_price'
export const URL_api_special_price = url_base + '/special_price'

//商品列表上下架
export const URL_api_parts_sku_onlineParts=url_base+'/api/parts/sku/onlineParts'
//商品列表删除
export const URL_api_parts_sku_delete=url_base+'/api/parts/sku/delete'
//商品新增
export const URL_api_parts_sku_getMarker=url_base+'/api/parts/sku/getMarker'
//商品上传excel
export const URL_api_parts_sku_importPartSKu=url_base+'/api/parts/sku/importPartSKu'
//下载模板
export const URL_excel_template_partsku=url_base+'/excel/template_partsku.xlsx'
//模糊查询零件
export const URL_api_parts_sku_getStdNameList=url_base+'/api/parts/sku/getStdNameList'
//获取配件品质
export const URL_api_parts_sku_getConfigQualitys=url_base+'/api/parts/sku/getConfigQualitys'
//获取质保期限
export const URL_api_parts_sku_getConfigWarrantys=url_base+'/api/parts/sku/getConfigWarrantys'
//商品保存提交
export const URL_api_parts_sku_save=url_base+'/api/parts/sku/save'
//商品图片的提交
export const URL_api_parts_sku_uploadPartImg=url_base+'/api/parts/sku/uploadPartImg'
//新增商品获取picId
export const URL_api_parts_sku_getPicId=url_base+'/api/parts/sku/getPicId'
//修改商品的详情页接口
export const URL_api_parts_sku_info=url_base+'/api/parts/sku/info'
//提交修改
export const URL_api_parts_sku_update=url_base+'/api/parts/sku/update'
//个人设置页面
export const URL_api_vendor_account_info=url_base+'/vendor/account/info/'
//公司设置
export const URL_api_vendor_account_companyInfo=url_base+'/vendor/account/companyInfo/'
//修改密码
export const URL_api_reset_password=url_base+'/api/reset_password'
//短信验证码
export const URL_api_get_verification_code=url_base+'/api/get_verification_code'

// 智能报价第三步获取报价
export const URL_gid_std_for_smartprice = url_base+'/gid_std_for_price'
//dashboart
export const URL_get_dashboard = url_base+ '/dashboard'

export const URL_dash2=url_base+'/dashboard'
export const URL_tongji=url_base+'/tongji'
//品牌车型
export const URL_Brand_models =url_base+'/brand_maker';

export const URL_Model_Stdoe =url_base+'/model_stdoe';

export const URL_Price_cube = url_base+'/price_cube';

export const URL_Part_Adaper = url_base+'/part_adapter'

//报价中心

export const URL_Offer_record = url_base+'/offer_record'

//报价详情
export const URL_0ffer_history = url_base+'/offer_history'

// excel表格上传
export const URL_gid_std_for_price = url_base+'/gid_std_for_price'
export const URL_share_exeal = url_base+'/share_for_me'
export const URL_special_exeal = url_base+ '/special_price'
//订单中心

export const URL_Order_list = url_base+'/order_list'

export const URL_Order_center = url_base+'/order_center'

//汽修地图

export const URL_Auto_repair = url_base+'/repair_shop'

export const URL_my_favorite_repair_shop = url_base+'/my_favorite_repair_shop'

//查询marker
export const URL_api_parts_sku_searchMaker=url_base+'/api/parts/sku/searchMaker'
//查询model
export const URL_api_parts_sku_searchModel=url_base+'/api/parts/sku/searchModel'
//查询YearRange
export const URL_api_parts_sku_searchYearRange=url_base+'/api/parts/sku/searchYearRange'
//查询Capacity
export const URL_api_parts_sku_searchCapacity=url_base+'/api/parts/sku/searchCapacity'
//查询询价单
// export const URL_smart_sale_search=url_base+'/search'
export const URL_smart_sale_search=url_base+'/search'
//手选车型数据
export const URL_api_parts_sku_searchAutoGroup=url_base+'/api/parts/sku/searchAutoGroup'
//搜索车型代码
export const URL_api_parts_sku_searchModelCode=url_base+'/api/parts/sku/searchModelCode'
//加入购物车
export const URL_shop_car=url_base+'/shop_car'
//提示
export const URL_com=url_base+'/tuisong'
//调货列表
export const URL_change_shop=url_base+'/change_shop'
//特价
export const URL_special_price=url_base+'/special_price'

// 智能EPC
export const URL_epc_brand = url_base +'/brand'
export const URL_epc_group_mapping = url_base + '/groupid_mapping'

// 智能销售 询价人订单 透明修订单
// export const URL_smart_sale = url_base + '/smart_sale'//原来是这个
export const URL_smart_sale = url_base + '/smart_sale'

//add
export const order_manager_query = url_base + '/order_manager/query/'
export const order_manager_add = url_base + '/order_manager/add/'

// export const URL_smart_sale77 = url_base + '/smart_sale'
export const URL_smartepc_save = url_base + '/sale_save'
export const URL_smartepc_submit = url_base + '/submit'

//保险直供模块
export const URL_chat = url_base + '/chat'
export const URL_risk = url_base + '/risk'

// 发送报价给云险
export const URL_modification = url_base + '/modification'
export const URL_delete = url_base + '/delete'

// 查勘员订单
export const URL_logistics_save = url_base + '/logistics_save'
// 保险直供订单图片上传
export const URL_upLoad = url_base + '/upload/'
const urlTest = 'http://148.70.137.178:9001'
// 自动报价和自动发送展示
export const auto_info = url_base + '/auto_info/show/'
// 自动报价和自动发送修改
export const auto_set = url_base + '/auto_info/auto_set/'
//智能销售 订单
// export const orderTouMingXiuSearch = url_base + '/order_zpb/search/'
export const orderTouMingXiuSearch = url_base + '/order_manager/query/'
export const orderToumingxiuDetail = url_base + '/order_zpb/detail/'
//修改订单信息
export const orderManagerUpdate=url_base + '/order_manager/update/'
export const get_code=url_base+'/get_code/'
export const change_password=url_base+'/change_password/'
